<template>
    <div class="banner-component-container flex-row ai-c jc-c">
        <div class="banner-component-container__image-container flex-row ai-c jc-c">
           <el-image v-if="isProjectOwner"
            class="cohort-banner-po-img"
            fit="contain"
            :src="require('@/assets/images/birmingham-cohort-po-banner.png')"
            ></el-image>
           <el-image v-if="isTradesperson"
            fit="contain"
            :src="require('@/assets/images/performance-through-people-logo.png')"
            ></el-image>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['isTradesperson', 'isProjectOwner']),
  }
};
</script>
<style lang="scss" scoped>
.banner-component-container {
    width: 100%;
    background: #FFF;
    box-shadow: 0px 0px 20px rgba(12, 15, 74, 0.1);
    border-radius: 30px;
    height: 180px;

    .cohort-banner-po-img {
      object-fit: contain;
      width: 360px;
      text-align: center;
      display: block;
      margin: 0 auto;
    }
}
</style>
