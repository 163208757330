<template>
    <div class="schedule-filter flex-row ai-c">
      <p class="schedule-filter__tag default-text"
      :class="{'active': activeIndex === index + 1}"
      v-for="(item, index) in filters" :key="index"
      @click="setActiveIndex(item.key)">
      {{item.name}}</p>
    </div>
</template>
<script>
export default {
  data() {
    return {
      activeIndex: 1,
      filters: [
        {
          key: 1,
          name: 'Upcoming'
        },
        {
          key: 2,
          name: 'Completed'
        }
      ]
    };
  },
  methods: {
    setActiveIndex(key) {
      this.activeIndex = key;
    }
  },
};
</script>
<style lang="scss" scoped>
.schedule-filter {

    &__tag {
        font-size: 16px;
        font-weight: 600;
        line-height: 30px;
        letter-spacing: 0px;
        color: rgba(12, 15, 74, 0.5);
        position: relative;
        cursor: pointer;
    }
    &__tag.active {
        font-weight: 700;
        color: #0C0F4A;

        &::after {
          content: '';
          position: absolute;
          bottom: -.1rem;
          left: 0;
          right: 0;
          width: 100%;
          height: 2px;
          background: #5DB9E7;
        }
    }
}
</style>
