<template>
    <div class="cohorts-invoice flex-column">
        <CohortsHeaderComponent/>
        <div class="cohort-invoice__wrapper flex-column">
           <CohortsListHeaderComponent/>
           <GenerateInvoiceList v-if="isProjectOwner"/>
           <PayInvoiceList v-if="isTradesperson"/>
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import GenerateInvoiceList from '@/modules/cohort-invoice/components/GenerateInvoiceList.vue';
import PayInvoiceList from '@/modules/cohort-invoice/components/PayInvoiceList.vue';
import CohortsListHeaderComponent from '@/modules/cohorts/components/CohortsListHeaderComponent.vue';
import CohortsHeaderComponent from '@/modules/dashboard/components/cohort-components/CohortsHeaderComponent.vue';

export default defineComponent({
  components: {
    CohortsHeaderComponent,
    CohortsListHeaderComponent,
    GenerateInvoiceList,
    PayInvoiceList
  },
  computed: {
    ...mapGetters(['isTradesperson', 'isProjectOwner']),
  }
});
</script>
<style lang="scss" scoped>
.cohorts-invoice {
    max-width: 1230px;
    gap: 1.6rem;
    position: relative;
}
</style>
