<template>
    <div class="cohorts-lists">
        <el-table :data="cohortsList" style="width: 100%">
          <el-table-column label="Learner name">
            <template #default="scope">
                <p class="cohorts-lists__data default-text">{{ scope.row.learnerFirstName }} {{ scope.row.learnerLastName }}</p>
            </template>
          </el-table-column>
          <el-table-column label="Location" width="170">
            <template #default="scope">
                <p class="cohorts-lists__data default-text">{{ scope.row.location }}</p>
            </template>
          </el-table-column>
          <el-table-column label="Enrolment status" width="170">
            <template #default>
                <el-select class="members-role" v-model="statusValue" filterable placeholder="Status">
                    <el-option
                    v-for="item in status"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    />
                </el-select>
            </template>
          </el-table-column>
          <el-table-column label="Result" width="140">
            <template #default>
                <el-select class="members-role" v-model="resultValue" filterable placeholder="Result">
                    <el-option
                    v-for="result in results"
                    :key="result.value"
                    :label="result.label"
                    :value="result.value"
                    />
                </el-select>
            </template>
          </el-table-column>
          <el-table-column label="Course certificate" width="170">
            <template #default="scope">
                <p class="cohorts-lists__data default-text">{{ scope.row.courseCertificate === null && 'N/A' }}</p>
            </template>
          </el-table-column>
          <el-table-column label="Message" width="170">
            <template #default="scope">
              <div class="cohorts-lists__cta flex-row ai-c jc-sb">
                <p class="cohorts-lists__data default-text">Message {{ scope.row.learnerFirstName }}</p>
                <el-icon :size="12" color="#0C0F4A"><ArrowRightBold/></el-icon>
              </div>
            </template>
          </el-table-column>
      </el-table>
    </div>
</template>
<script>
import { ArrowRightBold } from '@element-plus/icons';

export default {
  data() {
    return {
      cohortsList: [
        {
          learnerFirstName: 'Craig',
          learnerLastName: 'David',
          location: 'WV10 9YQ',
          enrolmentStatus: '1',
          result: '1',
          courseCertificate: null,
        },
        {
          learnerFirstName: 'Jasper',
          learnerLastName: 'David',
          location: 'WV10 9YQ',
          enrolmentStatus: '2',
          result: '2',
          courseCertificate: null,
        },
        {
          learnerFirstName: 'Keano',
          learnerLastName: 'David',
          location: 'WV10 9YQ',
          enrolmentStatus: '2',
          result: '2',
          courseCertificate: null,
        },
        {
          learnerFirstName: 'Allar',
          learnerLastName: 'David',
          location: 'WV10 9YQ',
          enrolmentStatus: '1',
          result: '2',
          courseCertificate: null,
        },
        {
          learnerFirstName: 'Philip',
          learnerLastName: 'David',
          location: 'WV10 9YQ',
          enrolmentStatus: '1',
          result: '2',
          courseCertificate: null,
        },
        {
          learnerFirstName: 'Ken',
          learnerLastName: 'David',
          location: 'WV10 9YQ',
          enrolmentStatus: '2',
          result: '1',
          courseCertificate: null,
        },
        {
          learnerFirstName: 'Kirk',
          learnerLastName: 'David',
          location: 'WV10 9YQ',
          enrolmentStatus: '1',
          result: '2',
          courseCertificate: null,
        },
        {
          learnerFirstName: 'Ufuk',
          learnerLastName: 'David',
          location: 'WV10 9YQ',
          enrolmentStatus: '2',
          result: '2',
          courseCertificate: null,
        },
      ],
      resultValue: '',
      results: [
        {
          value: null,
          label: 'N/A',
        },
        {
          value: 1,
          label: 'Pass',
        },
        {
          value: 2,
          label: 'Fail',
        },
      ],
      statusValue: '',
      status: [
        {
          value: 1,
          label: 'Confirmed',
        },
        {
          value: 2,
          label: 'Rejected',
        },
      ],
    };
  },
  components: {
    ArrowRightBold
  },
  methods: {
    goToPayInvoicePage() {
      this.$router.push('/cohort-invoice');
    }
  }
};
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.default-text {
    font-family: Mulish;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.25px;
    text-align: left;
}
.bold-text {
    font-weight: 700;
}
.name-container {
  width: 90%;
  &__name {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    line-height: 1.2em;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}
.el-table {
  --el-table-tr-bg-color: transparent;
  --el-table-bg-color: transparent;
  --el-table-header-bg-color: transparent;
}
:deep(.el-table th.el-table__cell.is-leaf) {
  border-bottom: none;
}
:deep(.el-table__row) {
  cursor: pointer;
}
:deep(.cell) {
  display: flex;
  align-items: center;
  gap: 12px;
  color: #0c0f4a;
  font-size: 16px;
  font-weight: 700;
  padding: 0;
  font-family: Mulish;
  line-height: 24px;
  letter-spacing: 0.25px;
}

.cohorts-lists {
  &__cta {
    cursor: pointer;
    gap: .7rem;
    width: 100%;
  }
  .el-select {
    width: 100px;
    min-width: 100px;
  }
  :deep(.el-input__inner) {
    border: none;
    outline: none;
    background: transparent;
    padding-left: 0;
  }
  :deep(.el-select__caret.el-icon) {
    color: #0C0F4A;
  }
}

@include media-xs-max-width() {
  .default-text {
    font-size: 12px;
  }
  :deep(.cell) {
    font-size: 12px;
  }
}
</style>
