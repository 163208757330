<template>
    <div class="cohorts-list-header flex-column">
       <div class="cohorts-list-header__row flex-row ai-c jc-sb">
          <div class="cohorts-list-header__heading flex-row ai-c">
            <BackButton v-if="!isFilterHeader"/>
            <p class="cohorts-list-header__heading-title default-text">{{title}}</p>
          </div>
          <div class="cohorts-list-header__button-wrapper" v-if="!isFilterHeader">
            <el-button type="primary" class="cohorts-list-header__button flex-row ai-c jc-c"  @click="onPayInvoice" round :loading="submitting" v-if="isTradesperson">PAY £10,000</el-button>
            <el-button type="primary" class="cohorts-list-header__button flex-row ai-c jc-c"  @click="onGenerateInvoice" round :loading="submitting" v-if="isProjectOwner">Generate Invoice</el-button>
          </div>
       </div>
       <div class="cohorts-list-header__row flex-row ai-c jc-sb" v-if="isFilterHeader">
          <ScheduleFilterComponent/>
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import ScheduleFilterComponent from '@/core/components/common/header/ScheduleFilterComponent.vue';
import BackButton from '@/core/components/ui/BackButton.vue';

export default defineComponent({
  props: {
    isFilterHeader: {
      default: false,
      type: Boolean
    },
    title: {
      default: 'Cohorts',
      type: String
    }
  },
  components: {
    BackButton,
    ScheduleFilterComponent
  },
  computed: {
    ...mapGetters(['isTradesperson', 'isProjectOwner']),
  }
});
</script>
<style lang="scss" scoped>
.cohorts-list-header {
    &__heading-title {
        font-size: 24px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.25px;
    }
    &__button-wrapper {
        width: 220px;
    }
    &__button {
        width: 100%;
        height: 36px;
        font-family: Mulish;
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.15000000596046448px;
        text-align: center;
        color: #4F55F0;
        background: #FFFFFF;
        box-shadow: 0px 4px 20px rgba(42, 42, 42, 0.1);
        border-radius: 8px;
        border: none;
        outline: none;
        box-sizing: border-box;
        text-transform: uppercase;
    }
}
</style>
