<template>
    <div class="cohorts-lists">
        <el-table :data="cohortsList" style="width: 100%">
          <el-table-column label="Learner name">
            <template #default="scope">
                <p class="cohorts-lists__data default-text">{{ scope.row.learnerName }}</p>
            </template>
          </el-table-column>
          <el-table-column label="Location" width="170">
            <template #default="scope">
                <p class="cohorts-lists__data default-text">{{ scope.row.location }}</p>
            </template>
          </el-table-column>
          <el-table-column label="Result" width="140">
            <template #default="scope">
                <p class="cohorts-lists__data default-text">{{ scope.row.result }}</p>
            </template>
          </el-table-column>
          <el-table-column label="Course certificate" width="170">
            <template #default="scope">
                <p class="cohorts-lists__data default-text">{{ scope.row.courseCertificate === null && 'N/A' }}</p>
            </template>
          </el-table-column>
          <el-table-column label="Amount" width="120">
            <template #default="scope">
                <p class="cohorts-lists__data default-text">{{ scope.row.amount }}</p>
            </template>
          </el-table-column>
      </el-table>
    </div>
</template>
<script>
export default {
  data() {
    return {
      cohortsList: [
        {
          learnerName: 'Craig David',
          location: 'WV10 9YQ',
          result: 'Pass',
          courseCertificate: null,
          amount: '£750',
        },
        {
          learnerName: 'Craig David',
          location: 'WV10 9YQ',
          result: 'Faill',
          courseCertificate: null,
          amount: '£750',
        },
        {
          learnerName: 'Craig David',
          location: 'WV10 9YQ',
          result: 'Pass',
          courseCertificate: null,
          amount: '£750',
        },
        {
          learnerName: 'Craig David',
          location: 'WV10 9YQ',
          result: 'Fail',
          courseCertificate: null,
          amount: '£750',
        },
        {
          learnerName: 'Craig David',
          location: 'WV10 9YQ',
          result: 'Fail',
          courseCertificate: null,
          amount: '£750',
        },
        {
          learnerName: 'Craig David',
          location: 'WV10 9YQ',
          result: 'Pass',
          courseCertificate: null,
          amount: '£750',
        },
        {
          learnerName: 'Craig David',
          location: 'WV10 9YQ',
          result: 'Pass',
          courseCertificate: null,
          amount: '£750',
        },
        {
          learnerName: 'Craig David',
          location: 'WV10 9YQ',
          result: 'Pass',
          courseCertificate: null,
          amount: '£750',
        },
      ]
    };
  },
};
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.default-text {
    font-family: Mulish;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.25px;
    text-align: left;
}
.bold-text {
    font-weight: 700;
}
.name-container {
  width: 90%;
  &__name {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    line-height: 1.2em;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}
.el-table {
  --el-table-tr-bg-color: transparent;
  --el-table-bg-color: transparent;
  --el-table-header-bg-color: transparent;
}
:deep(.el-table th.el-table__cell.is-leaf) {
  border-bottom: none;
}
:deep(.el-table__row) {
  cursor: pointer;
}
:deep(.cell) {
  display: flex;
  align-items: center;
  gap: 12px;
  color: #0c0f4a;
  font-size: 16px;
  font-weight: 700;
  padding: 0;
  font-family: Mulish;
  line-height: 24px;
  letter-spacing: 0.25px;
}

.cohorts-lists {
}

@include media-xs-max-width() {
  .default-text {
    font-size: 12px;
  }
  :deep(.cell) {
    font-size: 12px;
  }
}
</style>
