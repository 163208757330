<template>
    <div class="cohort-column-content-container flex-column jc-sb">
        <div class="cohort-column-content-container__wrapper flex-column">
            <p class="cohort-column-content-container__data default-text">{{isProjectOwner ? 'Training provider' : 'Award Partner'}}</p>
            <p class="cohort-column-content-container__description default-text">{{isProjectOwner ? 'Birmingham Metropolitan College' : 'Performance Through People'}}</p>
        </div>
        <div class="cohort-column-content-container__wrapper flex-column">
            <p class="cohort-column-content-container__data default-text">Role</p>
            <p class="cohort-column-content-container__description default-text">{{isProjectOwner ? 'Tutor/Lecturer' : 'Finance Team'}}</p>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['isTradesperson', 'isProjectOwner']),
  }
};
</script>
<style lang="scss" scoped>
.cohort-column-content-container {
  &__wrapper {
    gap: 0;
  }

  &__data {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.25px;
    text-align: left;
  }

  &__description {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.25px;
    text-align: left;
  }
}
</style>
