<template>
    <div class="cohort-header-container grid">
      <BannerComponent/>
      <DefaultCardSlot :showDropdownMenu="false" cardHeight="180px">
        <AccountInfoComponent/>
      </DefaultCardSlot>
    </div>
</template>
<script>
import { defineComponent } from 'vue';

import DefaultCardSlot from '@/core/components/slots/DefaultCardSlot.vue';
import AccountInfoComponent from '@/modules/dashboard/components/cohort-components/AccountInfoComponent.vue';
import BannerComponent from '@/modules/dashboard/components/cohort-components/BannerComponent.vue';

export default defineComponent({
  components: {
    AccountInfoComponent,
    BannerComponent,
    DefaultCardSlot
  }
});
</script>
<style lang="scss" scoped>
.cohort-header-container {
    grid-template-columns: 2fr 1fr;
    padding: .9rem .5rem .5rem .5rem;
    gap: 1.6rem;
}
</style>
